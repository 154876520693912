<template>
    <Setup-Step
        @yes="stage += 1"
        @no="next"
        @restart="stage = 0"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :showRestart="stage != 0"
        :showSkip="stage == 0"
        step="fulfiller-services"
        title="3PL Services">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="couriers-fulfilling"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                            </v-list-item-content>
                            <!-- <v-list-item-action>
                                <v-btn small text @click="addNew(item)">change</v-btn>
                            </v-list-item-action> -->
                        </template>
                    </BT-List-Endless>

                    <p class="my-4">Would you like to add a 3PL Service?</p>
                </v-tab-item>

                <v-tab-item>
                    <!--see available 3PL services-->

                    <!-- <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" /> -->
                </v-tab-item>

               
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue';
import { getLocationLine, toCamelCase } from '~helpers';

export default {
    name: 'Courier-Setup',
    components: {
        // BTFileSelect: () => import('~components/BT-File-Select.vue'),
        // BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        // ExternalPartiesBlade: () => import('~home/external-parties/External-Parties-Blade.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            cacheData: new Date().getTime(),
            center: { lat: -38, lng: 144 },
            currentExternalParty: null,
            existingCustomers: [],
            isLegit: null,
            loadingMsg: null,
            newItem: {},
            newItems: [],
            orderingItem: null,
            refreshList: false,
            showServices: false,
            stage: 0
        }
    },
    computed: {
        google: getGoogleMapsAPI
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    },
    async mounted() {
        this.existingCustomers = await this.$BlitzIt.store.getAll('customers', { properties: 'ID,Buyer' });
    },
    methods: {
        addNew(j, stepsNum) {
            console.log('adding');
            console.log(j);
            this.newItem = j || { defaultTemplateID: null };
console.log(this.newItem);
            this.stage += stepsNum;

            if (j != null && j.companyName != null) {
                this.companyNameIsLegit(j.companyName);
            }
        },
        companyNameIsLegit(companyName) {
            this.isLegit = companyName != null && !this.existingCustomers.some(x => x.buyer.companyName == companyName);
        },
        async findCoordinates() {
            this.loadingMsg = 'Loading Coordinates';
            this.stage += 1;

            await this.$gmapApiPromiseLazy();
            var address = getLocationLine(this.newItem, true);
            var request = { address: address };
            
            var geo = new this.google.maps.Geocoder();
            
            try {
                var res = await geo.geocode(request);
                res = res.results
                var selected = null;
            
                if (res.length > 1) {
                    selected = await this.$selectItemFrom({
                        items: res,
                        itemText: 'formatted_address',
                        required: true
                    });
                }
                else if (res.length == 1) {
                    selected = res[0];
                }

                if (selected != null) {
                    this.newItem.lat = selected.geometry.location.lat();
                    this.newItem.lng = selected.geometry.location.lng();
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async importCustomers(file) {
            if (!this.isLengthyArray(this.newItems)) {
                this.newItems = await this.importCustomersFromFile(file);
            }

            var customers = await this.$BlitzIt.store.getAll('customers', { properties: 'ID,Buyer' });

            this.newItems = this.newItems.filter(x => !customers.some(p => p.companyName == x.companyName));

            this.stage += 2;
        },
        next(nextFunc) {
            nextFunc();
        },
        
        async save() {
            try {
                this.loadingMsg = 'Saving';
                var res = null;

                if (this.newItem.id == null) {
                    res = await this.$BlitzIt.api.post('new-customers', { subscriptionCode: 'CUSTFREE', companies: [this.newItem] }, null, '/PostCustomers');
                    this.existingCustomers.push({
                        id: null,
                        buyer: { companyName: this.newItem.companyName }
                    });

                    var nInd = this.newItems.findIndex(x => x.companyName == this.newItem.companyName);
                    if (nInd >= 0) {
                        this.newItems.splice(nInd, 1);
                    }
                }
                else {
                    //res = await this.$BlitzIt.store.patch('products', this.newItem);
                }
                
                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;

                if (this.isLengthyArray(this.newItems)) {
                    this.stage = 2;
                }
                else {
                    await this.twiddleThumbs(2000)
                    this.refreshList = !this.refreshList;
                    this.stage = 0;
                }
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async selectService(externalObj) {
            if (externalObj != null && externalObj.credential != null && externalObj.credential.isConnected) {
                this.currentExternalParty = externalObj;
                try {
                    this.loadingMsg = `Searching ${externalObj.optionName}`;
                    var syncRes = await this.$BlitzIt.api.getAll('customer-syncing', { partyID: externalObj.id, onlyUnsynced: true });
                    var convertFunc = this.$BlitzIt.navigation.findConvertFunc('customer-syncing', externalObj.id);
                    if (convertFunc != null) {
                        this.newItems = JSON.parse(syncRes.data.data, toCamelCase).map(x => convertFunc(x));
                    }
                    else {
                        this.newItems = JSON.parse(syncRes.data.data, toCamelCase);
                    }
                    
                    this.stage += 1;
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        }
    }
}
</script>